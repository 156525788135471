<template>
  <div>

    <br>

    <v-row justify="center">
      <v-dialog v-model="dialog" width="430px">
        <v-card>
          <v-card-text>
            <div class="text-center">
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y
                protecciones del</label>
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Plan <label style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                  font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                  color: #2D387C; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{ info[0][indexInfo]
                  }}</label> de
                Mascotas</label>
              <br>
              <br>
              <label class="text-center encabezado">Libre elección y sin deducible</label>
              <br>
              <br>
              <label class="texto">Puedes llevar a tu mascota a la
                clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además, todas
                nuestras coberturas son <label class="textoAzul">sin
                  deducible</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Urgencias Médicas</label>
              <br>
              <br>
              <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo] }}</label> de
                tus
                gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label
                  class="textoAzul">Tope
                  UF {{ info[2][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Consultas Médicas</label>
              <br>
              <br>
              <label class="texto">Reembolsamos las consultas médicas y
                controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                  {{ info[4][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y <label v-if="info[5][indexInfo] == 1" class="textoAzul">{{ info[5][indexInfo] }} vez al
                  año</label> <label v-if="info[5][indexInfo] != 1" class="textoAzul">{{ info[5][indexInfo] }} veces al
                  año</label>.</label>
              <br>
              <br>

              <div v-if="indexInfo != 3">

                <label class="text-center encabezado">Medicamentos</label>
                <br>
                <br>
                <label class="texto">¿Le recetaron remedios a tu mascota?
                  Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                  veterinarios con receta.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                  contratación y <label class="textoAzul">1 vez al mes</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Vacuna Antirrábica</label>
                <br>
                <br>
                <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                  la vacuna de tu mascota en cualquier clínica.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                  contratación y <label class="textoAzul">1 vez al año</label>.</label>
                <br>
                <br>

              </div>

              <div v-if="indexInfo == 3">

                <label class="text-center encabezado">Vacunas Obligatorias</label>
                <br>
                <br>
                <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                  la vacuna de tu mascota en cualquier clínica.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y <label class="textoAzul">2 veces al año</label>.</label>
                <br>
                <br>

              </div>

              <label class="text-center encabezado">Responsabilidad Civil</label>
              <br>
              <br>
              <label class="texto">Tu mascota se portó mal y mordió o
                hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                  info[8][indexInfo] }}
                </label> si tienes que pagar por los
                daños.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Telemedicina Veterinaria</label>
              <br>
              <br>
              <label class="texto">¿Tienes dudas de salud de tu mascota?
                Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">{{ info[9][indexInfo] }} días</label> de
                contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>

              <div v-if="indexInfo != 3">

                <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
                <br>
                <br>
                <label class="texto">¿Necesitas ayuda con temas legales
                  por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                  necesites.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al año</label>.
                </label>

              </div>

              <div v-if="indexInfo == 3">

                <label class="text-center encabezado">Cremación</label>
                <br>
                <br>
                <label class="texto">Si a consecuencia de un accidente o por vejez tu mascota fallece, te apoyamos con
                  <label class="textoAzul">3 UF.</label></label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul"> 10 días</label>
                  de
                  contratación.</label>

              </div>

              <br>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialog = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div v-if="loadingPlans == false">

      <div v-if="tipoPlan == false">

        <div class="planInidivual" style="text-align: center; min-width: 260px;">

          <br>

          <div style="text-align: left;">

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planInidivualName">{{ planData[0].name }}</label>

          </div>

          <label class="planIndividualPrecio">{{
            formatPrice(planData[0].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
              (planData[0].values[0].price).toFixed(2) }} mensual</label></label>

          <!-- <br> -->

          <!-- <label class="planIndividualDetalle">*Precio basado en la edad del
            contratante.</label> -->

          <br>

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasLiteInput == false" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

            <label v-if="verMasLiteInput == true" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

          </div> -->

          <br>

          <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
            <v-btn :class="newElegirPlanIndividual" style="background-color: #FFB600;" @click="next(planData[0])">
              <label style="color: white;">Elegir plan</label>
            </v-btn>
          </div>

          <br>

          <div v-if="verMasLiteInput == true" class="text-center">

            <p></p>
            <p class="planIndividualCaracteristicas">Características</p>
            <p></p>

            <li class="planIndividualBeneficios">Cubre {{ planData[0].coverage[0].amount }}% en Urgencia con tope de UF
              {{ planData[0].coverage[4].amount }}.</li>
            <p></p>
            <li class="planIndividualBeneficios">UF {{ planData[0].coverage[1].amount }} en Responsabilidad Civil.</li>
            <p></p>
            <li class="planIndividualBeneficios">UF {{ planData[0].coverage[2].amount }} en Consultas Médicas Libre.
            </li>
            <p></p>
            <li class="planIndividualBeneficios">$10.000 x mes en reembolso de medicamentos (Tope 50% boleta)</li>
            <p></p>
            <li class="planIndividualBeneficios">Asistencia Legal Teléfonica.</li>

            <br>

            <label class="planIndividualVerDetalle" @click="verDetalle(0)">Ver detalle</label>

            <br>
            <br>

          </div>

        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasLite()">
              <template v-slot:actions>
                <label style="flex-direction: column; align-items: center; margin-left: -95%"
                  v-if="verMasLiteInput == false">Ver más</label>
                <v-icon v-if="verMasLiteInput == false" style="color: white; margin-top: -5%;">
                  mdi-menu-down
                </v-icon>
                <label style="flex-direction: column; align-items: center; margin-left: -75%"
                  v-if="verMasLiteInput == true">Ver menos</label>
                <v-icon v-if="verMasLiteInput == true" style="color: white; margin-top: -5%;">
                  mdi-menu-up
                </v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

        <div class="planFamiliar" style="text-align: center;">

          <div class="planFamiliarRecomendado">

            <label class="planFamiliarConveniente">El Más conveniente</label>

          </div>

          <div style="text-align: left;">

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planFamiliarName">{{ planData[1].name }}</label>

          </div>

          <label class="planFamiliarPrecio">{{
            formatPrice(planData[1].priceCLP) }}*
            <label class="planFamiliarSubPrecio">/ UF {{ (planData[1].values[0].price).toFixed(2) }}
              mensual</label></label>

          <!-- <br> -->

          <!-- <label class="planFamiliarDetalle">*Precio basado en la edad del
            contratante.</label> -->


          <br>

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasBasicoInput == false" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

            <label v-if="verMasBasicoInput == true" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

          </div> -->

          <br>

          <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
            <v-btn :class="newElegirPlanFamiliar" style="background-color: #FFB600;" @click="next(planData[1])">
              <label style="color: white;">Elegir plan</label>
            </v-btn>
          </div>

          <br>

          <div v-if="verMasBasicoInput == true" class="text-center">

            <p></p>
            <p class="planFamiliarCaracteristicas">Características</p>
            <p></p>

            <li class="planFamiliarBeneficios">Cubre {{ planData[1].coverage[0].amount }}% en Urgencia con tope de UF {{
              planData[1].coverage[4].amount }}.</li>
            <p></p>
            <li class="planFamiliarBeneficios">UF {{ planData[1].coverage[1].amount }} en Responsabilidad Civil.</li>
            <p></p>
            <li class="planFamiliarBeneficios">UF {{ planData[1].coverage[2].amount }} en Consultas Médicas Libre.</li>
            <p></p>
            <li class="planFamiliarBeneficios">$10.000 x mes en reembolso de medicamentos (Tope 50% boleta)</li>
            <p></p>
            <li class="planFamiliarBeneficios">Asistencia Legal Teléfonica.</li>

            <br>

            <label class="planFamiliarVerDetalle" @click="verDetalle(1)">Ver detalle</label>

            <br>
            <br>

          </div>
        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasBasico()">
              <template v-slot:actions>
                <div>
                  <label style="flex-direction: column; align-items: center; margin-left: -95%"
                    v-if="verMasBasicoInput == false">Ver más</label>
                  <v-icon v-if="verMasBasicoInput == false" style="color: white; margin-top: -5%;">
                    mdi-menu-down
                  </v-icon>
                  <label style="flex-direction: column; align-items: center; margin-left: -75%"
                    v-if="verMasBasicoInput == true">Ver menos</label>
                  <v-icon v-if="verMasBasicoInput == true" style="color: white; margin-top: -5%;">
                    mdi-menu-up
                  </v-icon>
                </div>
              </template>

            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

        <div class="planInidivual" style="text-align: center;">

          <br>

          <div style="text-align: left;">

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planInidivualName">{{ planData[2].name }}</label>

          </div>

          <label class="planIndividualPrecio">{{
            formatPrice(planData[2].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
              (planData[2].values[0].price).toFixed(2) }} mensual</label></label>

          <!-- <br> -->

          <!-- <label class="planIndividualDetalle">*Precio basado en la edad del
            contratante.</label> -->

          <br>

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasPlusInput == false" class="planIndividualProtegete"><b>Protege
                tu salud y tu tranquilidad financiera</b></label>

            <label v-if="verMasPlusInput == true" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

          </div> -->

          <br>

          <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
            <v-btn :class="newElegirPlanIndividual" style="background-color: #FFB600;" @click="next(planData[2])">
              <label style="color: white;">Elegir plan</label>
            </v-btn>
          </div>

          <br>

          <div v-if="verMasPlusInput == true" class="text-center">

            <p></p>
            <p class="planIndividualCaracteristicas">Características</p>
            <p></p>

            <li class="planIndividualBeneficios">Cubre {{ planData[2].coverage[0].amount }}% en Urgencia con tope de UF
              {{
                planData[2].coverage[4].amount }}.</li>
            <p></p>
            <li class="planIndividualBeneficios">UF {{ planData[2].coverage[1].amount }} en Responsabilidad Civil.</li>
            <p></p>
            <li class="planIndividualBeneficios">UF {{ planData[2].coverage[2].amount }} en Consultas Médicas Libre.
            </li>
            <p></p>
            <li class="planIndividualBeneficios">$10.000 x mes en reembolso de medicamentos (Tope 50% boleta)</li>
            <p></p>
            <li class="planIndividualBeneficios">Asistencia Legal Teléfonica.</li>

            <br>

            <label class="planIndividualVerDetalle" @click="verDetalle(2)">Ver detalle</label>

            <br>
            <br>

          </div>

        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasPlus()">
              <template v-slot:actions>
                <label style="flex-direction: column; align-items: center; margin-left: -95%"
                  v-if="verMasPlusInput == false">Ver más</label>
                <v-icon v-if="verMasPlusInput == false" style="color: white; margin-top: -5%;">
                  mdi-menu-down
                </v-icon>
                <label style="flex-direction: column; align-items: center; margin-left: -75%"
                  v-if="verMasPlusInput == true">Ver menos</label>
                <v-icon v-if="verMasPlusInput == true" style="color: white; margin-top: -5%;">
                  mdi-menu-up
                </v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

      </div>

      <div v-if="tipoPlan == true">

        <div class="planFamiliar" style="text-align: center; min-width: 260px;">

          <div class="planFamiliarRecomendado">

            <label class="planFamiliarConveniente">El Más conveniente</label>

          </div>

          <div style="text-align: left;">

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label style="text-align: left;" class=" text-center planFamiliarName">{{
              planSenior[0].name
            }}</label>

          </div>

          <label class="planFamiliarPrecio">{{
            formatPrice(planSenior[0].priceCLP) }}*
            <label class="planFamiliarSubPrecio">/ UF {{ (planSenior[0].values[0].price).toFixed(2) }}
              mensual</label></label>

          <!-- <br> -->

          <!-- <label class="planFamiliarDetalle">*Precio basado en la edad del
            contratante.</label> -->


          <br>

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasSeniorInput == false" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

            <label v-if="verMasSeniorInput == true" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

          </div> -->

          <br>

          <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
            <v-btn :class="newElegirPlanFamiliar" style="background-color: #FFB600;" @click="next(planSenior[0])">
              <label style="color: white;">Elegir plan</label>
            </v-btn>
          </div>

          <br>

          <div v-if="verMasSeniorInput == true" class="text-center">

            <p></p>
            <p class="planFamiliarCaracteristicas">Características</p>
            <p></p>

            <li class="planFamiliarBeneficios">{{ planSenior[0].coverage[1].description }} {{
              planSenior[0].coverage[1].description2 }}.</li>
            <p></p>
            <li class="planFamiliarBeneficios">{{ planSenior[0].coverage[2].description }}.</li>
            <p></p>
            <li class="planFamiliarBeneficios">{{ planSenior[0].coverage[3].description }}.</li>
            <p></p>
            <li class="planFamiliarBeneficios">{{ planSenior[0].coverage[5].description }}.</li>

            <br>

            <label class="planFamiliarVerDetalle" @click="verDetalle(3)">Ver detalle</label>

            <br>
            <br>

          </div>
        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasSenior()">
              <template v-slot:actions>
                <div>
                  <label style="flex-direction: column; align-items: center; margin-left: -95%"
                    v-if="verMasSeniorInput == false">Ver más</label>
                  <v-icon v-if="verMasSeniorInput == false" style="color: white; margin-top: -5%;">
                    mdi-menu-down
                  </v-icon>
                  <label style="flex-direction: column; align-items: center; margin-left: -75%"
                    v-if="verMasSeniorInput == true">Ver menos</label>
                  <v-icon v-if="verMasSeniorInput == true" style="color: white; margin-top: -5%;">
                    mdi-menu-up
                  </v-icon>
                </div>
              </template>

            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>

    </div>

  </div>
</template>

<script>
import ShieldService from "@/services/shield.service.js";

import CryptoJS from 'crypto-js'

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "loadingPlans", "tipoPlan", "planSenior", "planDataAux", "servicesData"],
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,
      newElegirPlanIndividual: 'elegirPlan',
      newElegirPlanFamiliar: 'elegirPlan',
      stepperColor: '#FFB600',
      verMasLiteInput: false,
      verMasBasicoInput: false,
      verMasPlusInput: false,
      verMasSeniorInput: false,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      formatterUF: new Intl.NumberFormat("es-CL"),
      stepId: null,
      e1: 3,
      info: [['Plan Lite', 'Plan Básico', 'Plan Plus', 'Plan Senior'], ['40%', '60%', '80%', '50%'], ['50', '100', '250', '20'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '4'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['3', '10', '15', '8'], ['15', '15', '15', '30']],
      indexInfo: '0',
      dialog: false,
      document: null,
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
      ],
      activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    }
  },
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.stepId = sessionStorage.getItem('stepId');
    this.planData.sort((a, b) => b.priceCLP - a.priceCLP);
  },
  methods: {
    ufFormat(price) {
      return this.formatterUF.format(price);
    },
    VerMasLite() {
      if (this.verMasLiteInput == false) {
        this.verMasLiteInput = true
        this.newElegirPlanIndividual = 'elegirPlan2'
      } else {
        this.verMasLiteInput = false
        this.newElegirPlanIndividual = 'elegirPlan'
      }
    },
    VerMasBasico() {
      if (this.verMasBasicoInput == false) {
        this.verMasBasicoInput = true
        this.newElegirPlanIndividual = 'elegirPlan2'
      } else {
        this.verMasBasicoInput = false
        this.newElegirPlanIndividual = 'elegirPlan'
      }
    },
    VerMasPlus() {
      if (this.verMasPlusInput == false) {
        this.verMasPlusInput = true
        this.newElegirPlanFamiliar = 'elegirPlan2'
      } else {
        this.verMasPlusInput = false
        this.newElegirPlanFamiliar = 'elegirPlan'
      }
    },
    VerMasSenior() {
      if (this.verMasSeniorInput == false) {
        this.verMasSeniorInput = true
        this.newElegirPlanFamiliar = 'elegirPlan2'
      } else {
        this.verMasSeniorInput = false
        this.newElegirPlanFamiliar = 'elegirPlan'
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    verDetalle(index) {
      this.indexInfo = index;
      this.dialog = true;
    },
    async next(plan) {

      this.planDataAux.plan = plan;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      try {

        await ShieldService.newCreateStep(null, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, null, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/mt/datos' })
    },
  },
};
</script>

<style scoped>
.labelElige {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.planInidivual {
  width: 100%;
  background: linear-gradient(219deg, #F5F6FF 0%, #DADCFB 50%), #D8D8D8;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planInidivualDetalle {
  background: linear-gradient(192.38deg, #C4C6FF 3.57%, #F5F6FF 107.06%), #D8D8D8;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 327px;
}

.planInidivualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 29px;
  color: #343497;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #343497;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(53, 53, 53, 0.65);
}

.planIndividualDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(58, 58, 58, 0.85);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #343497;
}

.planIndividualVerMas {
  background: #617BFB;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
}

.planIndividualProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #343497;
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -15px;
  text-align: justify;
  margin: auto;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
}

.elegirPlan2 {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
}

.planFamiliar {
  width: 100%;
  background: linear-gradient(219deg, #7979FF 0%, #33C 82.29%), #D8D8D8;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 136px;
  height: 22px;
  border-radius: 27px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  background: #A74CF2;
  padding: 5px 25px;
  gap: 8px;
  width: 175px;
  height: 22px;
  border-radius: 27px;
  margin-top: -15%;
  margin-left: 45%;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 29px;
  color: #FFFFFF;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
}

.planFamiliarDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarVerMas {
  background: #A74CF2;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
  margin: auto;
}

.planFamiliarProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -15px;
  text-align: justify;
  margin: auto;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div:nth-child(11)>div:nth-child(2)>div {
  border-radius: 10px 10px 10px 10px;
}

.v-expansion-panel::before {
  content: none;
}
</style>